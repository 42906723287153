import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlockPrimeCard = ({ data }) => {
  const styles = {
    backgroundImage: "url("+data?.backgroundMedia?.localFile?.url+")",
    backgroundSize: "cover"
  }
  const titleStyles = {

  }
  const descriptionStyles = {

  }
  return (
    <div className="container max-w-4xl py-8" style={styles} className="text-center">

      {data?.topMedia &&
        <GatsbyImage
        image={getImage(data?.topMedia?.localFile)}
        alt={data?.topMedia?.alternativeText}
        className="mt-6"
        />
      }
      <div>
      </div>
      <h1 className="text-6xl font-bold text-center" style={titleStyles}>{data.title}</h1>
      <p className="mt-4 text-2xl text-white" style={descriptionStyles}>{data.description}</p>
    </div>
  )
}

export default BlockPrimeCard
