import React from "react"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="bg-neutral-100 py-2 text-white text-center text-xs" style={{backgroundColor:"#0052CC"}}>
      <div className="container">
        <p>Copyright © {currentYear} Save9 Technologies Pvt. Ltd. All rights reserved. <a href="/privacy/">Privacy</a> | <a href="/terms/">Terms</a></p>
      </div>
    </footer>
  )
}

export default Footer
