import React from "react"
import Footer from "./footer"
import Navbar from "./navbar"

const Layout = ({ children, pageStyle }) => {
  return (
    <div class="flex min-h-screen flex-col justify-between bg-neutral-50 text-neutral-900">
      <div>
        {pageStyle != "full" &&
        <Navbar />
        }
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
