import React from "react"

const BlockQuote = ({ data }) => {
  return (
    <div className="py-6">
      <blockquote className="container max-w-xl border-l-4 border-neutral-700 py-2 pl-6" style={{borderColor: "#0052CC"}}>
        <cite className="mt-4 block font-bold not-italic text-3xl">
          {data.title}
        </cite>
        { data.quoteBody &&
        <p className="font-medium italic">{data.quoteBody}</p>
        }
        { data.richTextBody &&
        <div
          dangerouslySetInnerHTML={{
            __html: data.richTextBody.data.childMarkdownRemark.html,
          }}
        />
        }
      </blockquote>
    </div>
  )
}

export default BlockQuote
