import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import PrimeCard from "../components/block-prime-card"
import BlocksRenderer from "../components/blocks-renderer"
import Seo from "../components/seo"

const GenericPage = ({ data }) => {
  const page = data.strapiPage

  if(page.seo === undefined || page.seo == null){
    page.seo = data.strapiGlobal.defaultSeo
  }

  const seo = {
    metaTitle: page.seo.metaTitle || page.title,
    metaDescription: page.seo.metaDescription || page.title,
    shareImage: page.seo.shareImage || page.cover,
  }

  return (
    <Layout as="page" pageStyle={page.style}>
      <Seo seo={seo} />
      <PrimeCard data={{title: page.title, description: page.description, backgroundMedia: page.cover}}/>
      {false &&
      <header className="container max-w-4xl py-8">
        <h1 className="text-6xl font-bold text-neutral-700">{page.title}</h1>
        <p className="mt-4 text-2xl text-neutral-500">{page.description}</p>
        <GatsbyImage
          image={getImage(page?.cover?.localFile)}
          alt={page?.cover?.alternativeText}
          className="mt-6"
        />
      </header>
      }
      <main className="mt-8">
        <BlocksRenderer blocks={page.blocks || []} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiGlobal {
        siteName
        siteDescription
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              url
              childImageSharp {
                gatsbyImageData
                fixed{
                  src
                }
              }
            }
          }
        }
      }
    strapiPage(slug: { eq: $slug }) {
      id
      slug
      title
      description
      style
      blocks {
        ...Blocks
      }
      seo {
        metaTitle
          metaDescription
          shareImage {
            localFile {
              url
              childImageSharp {
                gatsbyImageData
                fixed{
                  src
                }
              }
            }
          }
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
            fixed{
              src
            }
          }
        }
      }
    }
  }
`

export default GenericPage
